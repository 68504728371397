import axios, { InternalAxiosRequestConfig } from "axios";


import {
  handleConfigureAuth,
  handleAuthError,
  handleGeneralError,
  handleNetworkError,
} from "./tools";

const request = axios.create({
  // 公共配置，将来发送请求会自动添加
  // 发送请求的前缀：将来所有通过request发送的请求，会在请求地址前面自动添加baseURL
  // react脚手架会根据开发/生产自动加载.env.xxx的环境变量
  // 通过 process.env.xxx 读取加载的环境变量的值
  // 开发模式下：process.env.REACT_APP_API --> /dev-api
  // baseURL: process.env.REACT_APP_BASE_URL, // 请求地址：baseURL + url
  baseURL: '/api/v2',
  timeout: 15000, // 请求超时时间：一旦请求超过10s还未完成，就会自动中断请求
});

type Fn = (data: FcResponse<any>) => unknown;

interface IAnyObj {
  [index: string]: unknown;
}

interface FcResponse<T> {
  code: number;
  msg: string;
  data: T;
}

request.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const newConfig = handleConfigureAuth(config);
  return newConfig;
});

request.interceptors.response.use(
  (response) => {

    // 说明功能成功，返回成功的数据
    // message.success(response.data.msg);
    // return response.data; // 我们最终希望得到的数据
    // console.log(`response is `, response);
    if (response?.status !== 200) return Promise.reject(response.data);
    handleAuthError(response.data.code);
    handleGeneralError(response.data.code, response.data.msg);
    // return response.data; // 200 返回
    return response; // 200 返回
  },
  (err) => {
    // console.log('‘responseError:', err);

    handleNetworkError(err.response.status);
    return Promise.reject(err.response);
  }
);

export const Get = <T,>(
  url: string,
  params: IAnyObj = {},
  clearFn?: Fn
): Promise<[any, FcResponse<T> | undefined]> =>

  new Promise((resolve) => {
    request
      .get(url, { params })
      .then((result) => {

        // console.log(`result is `, result);
        let res: FcResponse<T>;
        if (clearFn !== undefined) {
          res = clearFn(result.data) as unknown as FcResponse<T>;
        } else {
          res = result.data as FcResponse<T>;
        }
        resolve([null, res as FcResponse<T>]);
      })
      .catch((err) => {
        resolve([err, undefined]);
      });
  });

export const Post = <T,>(
  url: string,
  data: IAnyObj,
  config: IAnyObj = {}
): Promise<[any, FcResponse<T> | undefined]> => {
  return new Promise((resolve) => {
    request
      .post(url, data, { ...config })
      .then((result) => {
        resolve([null, result.data as FcResponse<T>]);
      })
      .catch((err) => {
        resolve([err, undefined]);
      });
  });
};
