import { message } from "antd";
import { InternalAxiosRequestConfig } from "axios";
import { history } from 'umi';

export const handleChangeRequestHeader = <T>(config: T): T => {
  // config["xxxx"] = "xxx";
  return config;
};

export const handleConfigureAuth = (config: InternalAxiosRequestConfig) => {

  let token = localStorage.getItem('token') || ''
  // if (!token) return Promise.reject()
  if (token) {
    // 携带token参数
    (config.headers).Authorization = "Bearer" + " " + token;
  }
  return config;
};

export const handleNetworkError = (errStatus?: number): void => {
  const networkErrMap: any = {
    "400": "错误的请求", // token 失效
    "401": "未授权，请重新登录",
    "403": "拒绝访问",
    "404": "请求错误，未找到该资源",
    "405": "请求方法未允许",
    "408": "请求超时",
    "500": "服务器端出错",
    "501": "网络未实现",
    "502": "网络错误",
    "503": "服务不可用",
    "504": "网络超时",
    "505": "http版本不支持该请求",
  };
  if (errStatus) {
    message.error(networkErrMap[errStatus] ?? `其他连接错误 --${errStatus}`);
    return;
  }

  message.error("无法连接到服务器！");
};

export const handleAuthError = (code: number): boolean => {
  const authErrMap: any = {
    401: "身份过期，请重新登", // token 失效
  };

  if (authErrMap.hasOwnProperty(code)) {
    message.error(authErrMap[code]);
    // 授权错误，登出账户
    // logout();

    // 1.清空token信息
    localStorage.removeItem("token")
    // store.dispatch(logout())
    // 2. 路由跳转 -> login页面
    if (!window.location.pathname.includes('/login')) {
      history.push('/login');
    }
    return false;
  }

  return true;
};

export const handleGeneralError = (code: number, msg: string): boolean => {
  if (code !== 200) {
    message.error(msg);
    return false;
  }

  return true;
};
