import { Get, Post } from "../server";
import { nftListParam, nftListResponse } from "./types";
import { ApiResponse } from "./types";


export function getNftList<T = nftListResponse>(data: nftListParam): ApiResponse<T> {
  return Get<T>("/nft/list", { ...data });
}

export function userNFTApi<T = nftListResponse>(data: nftListParam): ApiResponse<T> {
  return Get<T>("/purchased-nft", { ...data });
}


export function getNftContentTypeList<T = nftListResponse>(): ApiResponse<T> {
  return Get<T>("/nft/nft-contents");
}


export function getStarNftList<T = nftListResponse>(data: nftListParam): ApiResponse<T> {
  return Get<T>("/star/nft", { ...data });
}


export function getStarCollectionList<T = nftListResponse>(data: nftListParam): ApiResponse<T> {
  return Get<T>("/star/collection", { ...data });
}

export function getNftDetail<T = any>(data: any): ApiResponse<T> {
  return Get<T>("/nft/detail", { ...data });
}

export function deleteNft<T = any>(data: any): ApiResponse<T> {
  return Post<T>("/nft/collection-delete", { ...data }, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
}

export function starSomething<T = any>(data: any): ApiResponse<T> {
  return Post<T>("/star", { ...data });
}


// 系列列表
export function getCollectionList<T = any>(data: any): ApiResponse<T> {
  return Get<T>("/collection/list", { ...data });
}

// 人气创作者列表
export function getCreatorList<T = any>(data: any): ApiResponse<T> {
  return Get<T>("/creator/list", { ...data });
}


