/*
 * @Author: CESC\cescx cescxu@qq.com
 * @Date: 2023-05-06 17:34:10
 * @LastEditors: CESC\cescx cescxu@qq.com
 * @LastEditTime: 2023-06-19 17:15:09
 * @FilePath: \new-dawn-web\src\global.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { getNftContentTypeList } from "./utils/http/path/nft";

async function initDict() {


    const [err, res] = await getNftContentTypeList()
    let result: any[] = [];

    if (!err && res?.data?.items) {
        const list: any[] = res?.data?.items?.map((item: any) => {
            return {
                ...item,
                key: item.id, name: item.contentName
            }
        })

        result = [...list]


    }
    localStorage.setItem("contentTypeDict", JSON.stringify(result))
}

initDict()